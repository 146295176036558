import axiosIns from "@/libs/axios";

const get = async (tipo_transaccion, sede_id) => {
  return await axiosIns.get("promociones");
};

const store = async (data) => {
  return await axiosIns.post("promociones", data);
};

const update = async (id, data) => {
  return await axiosIns.patch(`promociones/${id}`, data);
};

const destroy = async (id) => await axiosIns.delete(`promociones/${id}`);

const aprobar = async (id) => await axiosIns.post(`promociones/${id}`);

const findOne = async (id) => await axiosIns.get(`promociones/${id}`);

const findAllProductos = async (sede_id, promo_id) =>
  await axiosIns.get(
    `promociones/productos?sede_id=${sede_id}&promo_id=${promo_id}`
  );

export default {
  get,
  store,
  update,
  destroy,
  aprobar,
  findOne,
  findAllProductos,
};
