<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        class="ag-theme-balham ag-grid-table mb-1"
        :grid-options="gridOptions"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :row-data="items"
        row-selection="single"
        col-resize-default="shift"
        :animate-rows="false"
        :floating-filter="true"
        :pagination="true"
        :pagination-page-size="paginationPageSize"
        :row-class-rules="rowClassRules"
        :always-show-vertical-scroll="true"
        :suppress-pagination-panel="true"
        @grid-ready="onGridReady"
        @selection-changed="onSelectionChanged"
        @first-data-rendered="onFirstDataRendered"
      />
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                items.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : items.length
              }}
              de {{ items.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BCardText, BOverlay, BPagination, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCardText,
    AgGridVue,
    BOverlay,
    BPagination,
    BRow,
    BCol,
  },
  props: ["items", "loading"],
  data() {
    return {
      // grid
      gridOptions: {
        suppressPropertyNamesCheck: true,
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        // filter: true,
        // floatingFilter: true,
        // editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "NOMBRE",
          field: "nombre",
          autoHeight: true,
          wrapText: true,
          filter: false,
          minWidth: 300,
          maxWidth: 300,
        },
        {
          headerName: "DESCRIPCION",
          field: "descripcion",
          filter: false,
          minWidth: 350,
          maxWidth: 350,
        },
        {
          headerName: "CREADO POR",
          field: "creado_por",
          filter: false,
          minWidth: 180,
          maxWidth: 180,
        },
        {
          headerName: "APROBADO POR",
          field: "aprobado_por",
          filter: false,
          minWidth: 180,
          maxWidth: 180,
        },
        {
          headerName: "APROBADO",
          field: "aprobado_por",
          filter: false,
          cellRenderer(param) {
            return param.data.aprobado_por
              ? '<span style="color:#28c76f">APROBADO</span>'
              : '<span style="color:#FFB04C">PENDIENTE</span>';
          },
          minWidth: 100,
          maxWidth: 100,
        },

        {
          headerName: "FECHA INICIO",
          field: "fecha_inicio",
          filter: false,
          minWidth: 170,
          maxWidth: 170,
        },
        {
          headerName: "FECHA FIN",
          field: "fecha_fin",
          filter: false,
          minWidth: 170,
          maxWidth: 170,
        },
        {
          headerName: "ECOMMERCE",
          cellRenderer(param) {
            return param.data.ecommerce
              ? '<span class="badge badge-success">ECOMMERCE.</span>'
              : '<span class="badge badge-warning">P. VENTA</span>';
          },
          autoHeight: true,
          wrapText: true,
          filter: false,
          minWidth: 115,
          maxWidth: 115,
        },
        {
          headerName: "ESTADO",
          field: "activo",
          filter: false,
          cellRenderer(param) {
            return param.data.activo
              ? "ACTIVO"
              : '<span style="color:#ea5455;">INACTIVO</span>';
          },
          minWidth: 100,
          maxWidth: 100,
        },
      ],
      rowClassRules: { "row-red": "data.esta_activo === 0" },
      searchQuery: "",
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows !== "") {
        this.$emit("selectItemArticulo", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemArticulo", this.selectedRows, true);
      }
    },
  },
};
</script>

<style lang='scss'>
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham-dark .row-red {
  color: #ea5455 !important;
}
.boxA {
  height: 5rem;
}
.boxA img {
  width: 100%;
  height: 100%;
}
.box1 img {
  object-fit: cover;
  // object-fit: contain;
}
</style>
