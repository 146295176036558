<template>
  <b-modal
    id="modal-from-articulo"
    ref="modal"
    v-model="modalArticulo"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    variant="dark"
    button-size="sm"
    size="xl"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-tabs content-class="mt-2" justified>
      <b-overlay
        :show="submitted"
        no-wrap
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      />
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row class="mb-0-5">
            <b-col cols="4" md="4" lg="4" class="border-right">
              <b-row class="mb-0-5">
                <b-col cols="12" md="12" lg="12">
                  <b-form-group label="* NOMBRE" label-for="nombre">
                    <validation-provider
                      #default="{ errors }"
                      name="Descripcion"
                      rules="required|min:4"
                    >
                      <b-form-input
                        id="nombre"
                        v-model="form.nombre"
                        size="sm"
                        lazy-formatter
                        :formatter="textFormatter"
                        :state="errors.length > 0 ? false : null"
                        :disabled="aprobado !== null"
                      />
                      <small
                        v-for="error in errores.nombre"
                        :key="error"
                        class="text-danger"
                      >
                        {{ error }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-0-5">
                <b-col cols="12" md="12" xl="12">
                  <b-form-group
                    label="RANGO DE FECHAS:"
                    label-for="rangoFechas"
                  >
                    <flat-pickr
                      id="rangoFechas"
                      v-model="rangoFechas"
                      class="form-control form-control-sm-small"
                      :config="flatPickrConfig"
                      placeholder="Seleecione un rango de fechas"
                      @on-change="onChange"
                      @on-close="onClose"
                      :disabled="aprobado !== null"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-0-5">
                <b-col cols="12" md="6" lg="12">
                  <b-form-group label="DESCRIPCIÓN" label-for="descripcion">
                    <validation-provider
                      #default="{ errors }"
                      name="Descripción"
                      rules="max:1000"
                    >
                      <b-form-textarea
                        id="descripcion"
                        v-model="form.descripcion"
                        size="sm"
                        lazy-formatter
                        :formatter="textFormatter"
                        :state="errors.length > 0 ? false : null"
                        :disabled="aprobado !== null"
                        rows="5"
                      />
                      <small
                        v-for="error in errores.descripcion"
                        :key="error"
                        class="text-danger"
                        >{{ error }}</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" md="4" lg="4" class="border-right">
              <b-row class="mt-1 mb-2">
                <b-col cols="6" class="mb-0-5">
                  <b-form-checkbox
                    v-model="form.ecommerce"
                    :disabled="aprobado !== null"
                  >
                    ECOMMERCE
                  </b-form-checkbox>
                </b-col>
                <b-col cols="6">
                  <b-form-checkbox v-model="form.activo">
                    ESTA ACTIVO
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row v-if="!form.ecommerce" class="mb-0-5">
                <b-col cols="12" md="12" lg="12">
                  <b-form-group label="SEDES" label-for="sedes">
                    <v-select
                      id="sedes"
                      v-model="form.aplica_sede"
                      multiple
                      class="style-chooser select-size-sm"
                      :options="sedes"
                      label="nombre"
                      :disabled="aprobado !== null"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" md="4" lg="4">
              <div class="boxs boxs1 mb-1">
                <b-img
                  v-if="vistaPreviaImagen"
                  :src="vistaPreviaImagen"
                  center
                  thumbnail
                  fluid
                  class="rounded"
                  alt="Sin imagen"
                />
                <b-img
                  v-else
                  v-bind="imgAtributos"
                  rounded
                  alt="Rounded image"
                  class="d-inline-block mr-1 mb-1 border"
                />
              </div>
              <b-form-group label="IMAGEN DE PROMOCION" label-for="imagen">
                <validation-provider
                  #default="{ errors }"
                  name="imagen"
                  rules=""
                >
                  <b-form-file
                    id="wildcard"
                    v-model="form.imagen"
                    accept="image/*"
                    placeholder="Elija una imagen"
                    drop-placeholder="Ningun imagen seleccionado"
                    @change="onFileChanged"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.imagen"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- fila 1 -->
          <hr />
          <b-col cols="12" md="12" class="input-search" v-if="!aprobado">
            <div class="demo-inline-spacing mb-1">
              <b-button
                v-if="$hascan('promocion-registrar')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                @click="$refs.ModalSelectArticuloRef.focusBuscarArticulo()"
              >
                <feather-icon icon="PlusSquareIcon" class="mr-50" />
                <span class="align-middle">AGREGAR PRODUCTOS</span>
              </b-button>
            </div>
          </b-col>
          <ModalSelectArticulo
            ref="ModalSelectArticuloRef"
            es-servicio="1"
            @selectItemArticulo="selectItemArticulo"
          />
          <b-row>
            <b-col cols="12">
              <ag-grid-vue
                :grid-options="gridOptions"
                class="ag-theme-balham ag-grid-table-promocion mb-1"
                :column-defs="columnDefs"
                :default-col-def="defaultColDef"
                :row-data="promocionProducto"
                :suppress-row-click-selection="true"
                col-resize-default="shift"
                :animate-rows="false"
                :row-selection="rowSelection"
                :floating-filter="false"
                :always-show-vertical-scroll="true"
                :get-row-style="getRowStyle"
                @grid-ready="onGridReady"
                @rowClicked="onRowClicked($event)"
                @first-data-rendered="onFirstDataRendered"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-tabs>
  </b-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  VBModal,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BTabs,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import ModalSelectArticulo from "@/modules/components/ModalSelectArticulo.vue";
import SedeServices from "@/modules/configuracion/sede/services/index";
// import CheckboxRenderer from "./../CkeckboxRenderer.js";

import flatPickr from "vue-flatpickr-component";
import { formatearFecha } from "@/modules/reportes/utils";
import { AgGridVue } from "ag-grid-vue";
import PromocionServices from "../services/index";
import CheckboxRenderer from "./CheckboxRenderer.vue";
import formImagenMixin from "../../mixins/form-imagen-mixin";

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BTabs,
    BOverlay,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    vSelect,
    ModalSelectArticulo,
    AgGridVue,
    CheckboxRenderer,
    BFormFile,
    BImg,
  },
  mixins: [formImagenMixin],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["item"],
  data() {
    return {
      // Grid
      rowSelection: null,
      gridOptions: {
        localeText: {
          noRowsToShow: "",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      gridApi: null,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: false,
      },
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "PRODUCTO",
          field: "nombre",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "TIPO DESCUENTO",
          field: "es_porcentual",
          cellRenderer: "CheckboxRenderer",
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "DESCUENTO",
          field: "valor_descuento",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: precioNumberFormatter,
          valueParser: costoParser,
          editable(param) {
            if (!param.node.rowPinned) {
              return true;
            }
            return false;
          },
          singleClickEdit: true,
        },
        {
          headerName: "",
          filter: false,
          minWidth: 50,
          maxWidth: 50,
          cellRenderer(param) {
            if (param.node.rowPinned) {
              return undefined;
            }
            return '<button type="button" data-action-type="remove" class="btn btn-flat-danger btn-sm">X</button>';
          },
        },
      ],
      // End Grid

      dir: "ltr",
      flatPickrConfig: {
        minDate: new Date().fp_incr(-7),
        mode: "range",
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        defaultDate: [formatearFecha(new Date()), formatearFecha(new Date())],
      },
      rangoFechas: [formatearFecha(new Date()), formatearFecha(new Date())],
      buttonDisabled: true,
      form: {
        id: null,
        nombre: "",
        ecommerce: false,
        fecha_inicio: formatearFecha(new Date()),
        fecha_fin: formatearFecha(new Date()),
        imagen: null,
        aplica_sede: [],
        descripcion: null,
        activo: true,
        sede_id: this.mixing?.sede_id,
      },
      aprobado: false,
      sedes: [],
      promocionProducto: [],
      required,
      submitted: false,
      errores: [],
      modalArticulo: false,
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR PROMOCION" : "NUEVA PROMOCION";
    },
  },
  watch: {
    "form.nombre": function () {
      this.errores.descripcion = [];
    },
  },
  created() {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };

    this.rowSelection = "multiple";
    window.addEventListener("keyup", this.onkey);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onkey);
  },
  methods: {
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        const rowIndex = e.node.id;
        const actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    remover(index) {
      this.promocionProducto.splice(index, 1);
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    selectItemArticulo(producto, estado) {
      if (producto) {
        setTimeout(() => {
          this.agregarItem(producto, estado);
        }, 100);
      } else {
        this.$bvToast.toast(" POR FAVOR SELECCIONE UN ARTICULO ", {
          title: "ADVERTENCIA  !",
          variant: "warning",
          toaster: "b-toaster-top-center",
          solid: false,
        });
      }
    },
    agregarItem(producto, estado) {
      if (!this.encuentraArticulo(producto.id, estado)) {
        this.promocionProducto.push({
          id: producto.id,
          codigo: producto.codigo,
          nombre: producto.descripcion,
          producto_id: producto.id,
          es_porcentual: false,
          valor_descuento: 0,
        });

        if (estado) {
          setTimeout(() => {
            this.focusAgrid();
          }, 120);
        } else {
          setTimeout(() => {
            document.getElementById("codigo").focus();
            document.getElementById("codigo").select();
          }, 120);
          this.gridApi.redrawRows({ rowNodes: this.promocionProducto });
        }
      }
    },
    focusAgrid() {
      const cellrows = this.gridApi.getDisplayedRowCount();
      this.gridApi.setFocusedCell(cellrows - 1, "valor_descuento");
      this.gridApi.startEditingCell({
        rowIndex: cellrows - 1,
        colKey: "valor_descuento",
      });
    },
    encuentraArticulo(id, estado) {
      let sw = 0;
      for (let i = 0; i < this.promocionProducto.length; i++) {
        if (this.promocionProducto[i].id === id) {
          this.$bvToast.toast(" EL PRODUCTO YA FUE AGREGADO ", {
            title: "ADVERTENCIA  !",
            variant: "warning",
            toaster: "b-toaster-top-center",
            solid: false,
          });
          setTimeout(() => {
            this.focusAgridCantidad(i);
          }, 120);
          sw = true;
        }
      }
      return sw;
    },
    focusAgridCantidad(i) {
      this.gridApi.setFocusedCell(i, "valor_descuento");
      this.gridApi.startEditingCell({
        rowIndex: i,
        colKey: "valor_descuento",
      });
    },
    onChange(selectedDates, dateStr, instance) {
      if (selectedDates[0] && selectedDates[1]) {
        this.form.fecha_inicio = instance.formatDate(selectedDates[0], "Y-m-d");
        this.form.fecha_fin = instance.formatDate(selectedDates[1], "Y-m-d");
      }
    },
    onClose(selectedDates, dateStr, instance) {
      if (!selectedDates[0] || !selectedDates[1]) {
        this.form.fecha_inicio = null;
        this.form.fecha_fin = null;
      }
    },
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            if (!this.form.activo) {
              this.form.activo = false;
            }
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    async abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.nombre = "";
          this.form.descripcion = "";
          this.form.ecommerce = false;
          this.form.aplica_sede = [];
          this.form.activo = true;
          this.modalArticulo = true;
          this.promocionProducto = [];
          this.vistaPreviaImagen = "";
          this.rangoFechas = [new Date(), new Date()];
          this.form.sede_id = this.mixing.sede_id;
          this.aprobado = null;
          break;
        }
        case "actualizar": {
          const dataPromoDetalles = await PromocionServices.findOne(data.id);
          this.form.id = data.id;
          this.form.nombre = data.nombre;
          this.form.descripcion = data.descripcion;
          this.form.ecommerce = data.ecommerce;
          this.form.aplica_sede = data.aplica_sede || [];
          this.form.activo = data.activo;
          this.modalArticulo = true;
          const url = process.env.VUE_APP_URL_API;
          this.vistaPreviaImagen = data.imagen
            ? `${url.substring(0, url.length - 4)}images/${data.imagen}`
            : null;
          this.promocionProducto =
            dataPromoDetalles.data?.promocion_detalle || [];
          this.form.fecha_fin = data.fecha_fin;
          this.form.fecha_inicio = data.fecha_inicio;
          this.rangoFechas = [data.fecha_inicio, data.fecha_fin];
          this.form.sede_id = this.mixing.sede_id;
          this.aprobado = data.aprobado_por;

          break;
        }
        default:
          break;
      }
      this.getSedes();
      this.errores = [];
      setTimeout(() => {
        this.$nextTick(() => {
          document.getElementById("nombre").focus();
        });
      }, 100);
    },
    cerrarModal() {
      this.form.id = null;
      this.modalArticulo = false;
    },
    actualizarArticulo() {
      this.form.id = null;
      this.modalArticulo = false;
      this.$emit("actualizarArticulo");
      this.submitted = false;
    },
    generarFormData() {
      const formData = new FormData();
      formData.append("nombre", this.form.nombre);
      formData.append("ecommerce", this.form.ecommerce);
      formData.append("fecha_inicio", this.form.fecha_inicio);
      formData.append("fecha_fin", this.form.fecha_fin);
      formData.append("descripcion", this.form.descripcion);
      formData.append("activo", this.form.activo);

      if (this.form.aplica_sede.length)
        formData.append("aplica_sede", JSON.stringify(this.form.aplica_sede));

      if (this.promocionProducto.length)
        formData.append(
          "promociones_detalles",
          JSON.stringify(this.promocionProducto)
        );

      if (this.form.imagen) formData.append("file", this.form.imagen);

      return formData;
    },
    // SERVICES
    async registrar() {
      const formData = this.generarFormData();
      try {
        const response = await PromocionServices.store(formData);
        this.actualizarArticulo(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      const formData = this.generarFormData();
      try {
        const response = await PromocionServices.update(this.form.id, formData);
        this.actualizarArticulo(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(
          `${err.response.data.message}, Status: ${err.response.data.status}`,
          {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          }
        );
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    // Sedes
    async getSedes() {
      try {
        const response = await SedeServices.get({});
        this.sedes = response.data.map((g) => ({
          id: g.id,
          nombre: `${g.municipio.departamento.nombre} - ${g.municipio.nombre} - ${g.numero_sede}`,
          ...g,
        }));
      } catch (err) {
        this.$bvToast.toast(
          `${err.response.data.message}, Status: ${err.response.data.status}`,
          {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          }
        );
      }
    },
  },
};

function precioNumberFormatter(params) {
  return params.value ? params.value : Number(params.value).toFixed(2);
}
function costoParser(params) {
  return !isNaN(params.newValue) && params.newValue >= 0
    ? Number(params.newValue).toFixed(2)
    : params.data.costo;
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@/assets/scss/components/agGridStyle.scss";
</style>
