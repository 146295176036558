<template>
  <div class="custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      :checked="isChecked"
      @click="handleClick"
      :id="id"
    />
    <label class="custom-control-label" :for="id">
      {{ activo ? " Porcentaje" : " Monto" }}
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxRenderer",
  data() {
    return {
      activo: 0,
      id: 0,
    };
  },
  computed: {
    isChecked() {
      return this.activo;
    },
  },
  beforeMount() {
    this.activo = Number(this.params.value);
    this.id = this.params.data.id;
  },
  methods: {
    handleClick(event) {
      const { checked } = event.target;
      const { colId } = this.params.column;
      this.params.node.setDataValue(colId, checked);
    },
  },
};
</script>